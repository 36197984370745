import create from "zustand";
import debounce from "lodash-es/debounce";
import { filterProducts } from "../service/filter-products";
import {
  FacetOutputViewModel,
  FacetResultOutput,
  FilterProduct,
} from "../types/generated/sitecore.interface";
import { setSessionStorageItem } from "../helpers/storage.helper";
import { navigate } from 'gatsby'
import {IS_BROWSER} from '../../constants'
type IFacetChip = FacetResultOutput & { FacetName: string };

export type IProductListState = {
  loading: boolean;
  error: string;
  searchTerm: string;
  serializedQueryString?: string;
  products: FilterProduct[];
  facets: Record<string, FacetOutputViewModel>;
  activeFacets: IFacetChip[];
  categories?: FacetOutputViewModel;
  selectedCategory?: string;
  sortInfo?: FacetOutputViewModel;
  loadQueryString: (
    queryString: string,
    initialLoad?: boolean
  ) => Promise<void>;
  page: number;
  pageCount: number;
  resultCount: number;
  setPage: (pageIndex: number) => void;
  setFacet: (
    facetName: FacetOutputViewModel["Name"],
    value: string,
    selected: boolean
  ) => void;
  setSearchTerm: (term: string, doNotUpdate?: boolean) => void;
  clearFacets: (doNotUpdate?: boolean) => void;
  clearAllFilters: (doNotUpdate?: boolean) => void;
  sortBy: (value: string) => void;
  updateEverything: () => void;
  selectCategory: (category: string, doNotUpdate?: boolean) => void;
};

const mapActiveFacets = (
  facets: Record<string, FacetOutputViewModel>
): IFacetChip[] =>
  Object.values(facets)
    // We don't want the sorting facet included here.
    .filter((facet) => facet.Key !== "Sort")
    // Get just the facet options that are selected.
    .map((facet): IFacetChip[] =>
      (facet.FacetResults || [])
        .filter((facetOption) => facetOption.IsSelected)
        .filter(Boolean)
        .map(
          (facetOption): IFacetChip => ({
            ...facetOption,
            FacetName: facet.Key || "",
          })
        )
    )
    // Flatten this whole thing into a single array for easier iteration.
    .flat();

const setFacetValue = (
  facetResults: FacetOutputViewModel["FacetResults"] = [],
  value = "",
  selected = false,
  resetOthersToFalse = false
): FacetOutputViewModel["FacetResults"] => {
  const activeFacetIndex = facetResults.findIndex(
    (facet) => facet.Query?.Value === value
  );
  if (activeFacetIndex > -1) {
    return [
      ...facetResults
        .slice(0, activeFacetIndex)
        .map((facet) =>
          selected && resetOthersToFalse
            ? { ...facet, IsSelected: false }
            : facet
        ),
      {
        ...facetResults[activeFacetIndex],
        IsSelected: selected,
      },
      ...facetResults
        .slice(activeFacetIndex + 1)
        .map((facet) =>
          selected && resetOthersToFalse
            ? { ...facet, IsSelected: false }
            : facet
        ),
    ];
  }

  return facetResults;
};

export const useProductList = create<IProductListState>((set, get) => ({
  loading: false,
  error: "",
  searchTerm: "",
  serializedQueryString: IS_BROWSER ? window.location.search : '',
  products: [],
  facets: {},
  page: 0,
  pageCount: 0,
  resultCount: 0,
  activeFacets: [],

  loadQueryString: async (
    queryString: string,
  ): Promise<void> => {
    const queryStringToUse = queryString || window.sessionStorage.getItem('lastQueryString') || "Kategori=Mobiltelefoner";
    set({ serializedQueryString: queryStringToUse });
    setSessionStorageItem("lastQueryString", queryString || "");
    set({ loading: true });
      try {


        const data = await filterProducts(queryStringToUse);

        const facets: IProductListState["facets"] = {};
        (data.Facets || []).forEach(
          (facet) => (facets[facet.Key || ""] = facet)
        );
        const sortInfo = { ...facets.Sort };

        set({
          loading: false,
          products: data.Products || [],
          facets,
          activeFacets: mapActiveFacets(facets),
          sortInfo,
          error: "",
          page: data.PageIndex || 0,
          resultCount: data.TotalDocumentsFound || 0,
          pageCount: Math.ceil(
            (data.TotalDocumentsFound || 0) / (data.PageSize || 1)
          ),
          categories: data.Categories || {},
          selectedCategory:
            (data.Categories?.FacetResults || []).find(
              (facet) => facet.IsSelected
            )?.Query?.Value || "",
        });
      } catch (e: any) {
        set({ loading: false, error: e.message || e.toString() });
      }
  },

  updateEverything: debounce(
    (): void => {
      const allFacets = get().facets;
      const searchTerm = get().searchTerm;
      const pageIndex = get().page;
      const categories = get().categories;
      const selectedCategory = get().selectedCategory;
      const loadQueryString = get().loadQueryString;
      const queryStringParts = [
        Object.values(allFacets)
          .map((facet) =>
            facet.FacetResults?.filter((option) => option.IsSelected)
              .map((option) => `${facet.Key}=${option.Query?.EscapedValue}`)
              .join("&")
          )
          .filter(Boolean)
          .join("&"),
      ];

      // We need the active category, if one is set.
      if (categories && selectedCategory) {
        queryStringParts.push(
          `${categories.EscapedKey}=${encodeURIComponent(selectedCategory)}`
        );
      }

      if (searchTerm) {
        queryStringParts.push(`search=${encodeURIComponent(searchTerm)}`);
      }

      if (pageIndex) {
        queryStringParts.push(`PageIndex=${pageIndex}`);
      }

      const queryString = queryStringParts.filter(Boolean).join("&");

      // Now that we've built our query string, let's load it.
      loadQueryString(queryString);

      //TODO: Window might break everything here
      // Set the new query string in the browser for great justice.
      navigate(`${window.location.pathname}?${queryString}`);
    },
    500,
    { leading: true, trailing: true }
  ),

  sortBy: (value: string): void => {
    const sortData = get().sortInfo;
    const allFacets = get().facets;
    const updateEverything = get().updateEverything;

    if (!sortData) return;

    // Build updated sort-info. This will let the app show the new sorting choice immediately.
    const updatedSortData = {
      ...sortData,
      FacetResults: sortData.FacetResults?.map(
        (facet): FacetResultOutput => ({
          ...facet,
          IsSelected: facet.Query?.Value === value,
        })
      ),
    };
    // Since the query string is built from the facets, and this is technically just a facet, we'll need to update the
    // sort-facet now.
    allFacets[sortData.Key || ""] = updatedSortData;

    set({
      facets: allFacets,
      activeFacets: mapActiveFacets(allFacets),
      sortInfo: updatedSortData,
    });
    updateEverything();
  },

  setFacet: (
    facetName: FacetOutputViewModel["Name"] = "",
    value: string,
    selected: boolean
  ) => {
    const allFacets = get().facets;
    const updateEverything = get().updateEverything;
    const facetFamily = allFacets[facetName];

    if (!facetFamily.FacetResults) return;

    const transformedFacet = setFacetValue(
      facetFamily.FacetResults,
      value,
      selected
    );
    const facets = {
      ...allFacets,
      [facetName]: { ...facetFamily, FacetResults: transformedFacet },
    };
    set({ facets, activeFacets: mapActiveFacets(facets), page: 0 });
    updateEverything();
  },

  addFacet: (facetName: FacetOutputViewModel["Name"], value: string): void => {
    get().setFacet(facetName, value, true);
  },

  clearFacets: (doNotUpdate = false): void => {
    const allFacets = get().facets;
    const facetFamilies = Object.values(allFacets || {});
    if (!facetFamilies.length) return;

    const transformedFacets = facetFamilies.map((family) => ({
      ...family,
      FacetResults: (family.FacetResults || []).map((result) => ({
        ...result,
        IsSelected: false,
      })),
    }));
    transformedFacets.forEach(
      (family) => (allFacets[family.Key || ""] = family)
    );

    set({ facets: allFacets, activeFacets: [] });
    if (!doNotUpdate) {
      get().updateEverything();
    }
  },

  selectCategory: (categoryName, doNotUpdate = false) => {
    const categories = get().categories;
    if (!categories) return;

    const transformedCategories = categoryName
      ? setFacetValue(categories.FacetResults, categoryName, true, true)
      : categories.FacetResults?.map((facet) => ({
        ...facet,
        IsSelected: false,
      }));

    // You may be wondering why we're setting both a string value AND updating the internal object of values? Elementary:
    // the string value is used to communicate with the server, and is set because we may want to "hard-override" the selected
    // category by selecting a category that isn't present in the current filter (like, say, if you want to select
    // "Mobile phones" from the search overlay, but only "Covers" is available in the current setup).
    // So why the object? Because we ALSO want immediate feedback on the new selection, and updating the "IsSelected"
    // status is an easy way of going about that. Of course, we could just rely on the string value alone for marking
    // stuff as active, but that's almost too easy.
    set({
      categories: { ...categories, FacetResults: transformedCategories },
      selectedCategory: categoryName,
      page: 0,
    });

    if (!doNotUpdate) {
      get().updateEverything();
    }
  },

  clearAllFilters: (doNotUpdate = false) => {
    get().selectCategory("", true);
    get().clearFacets(true);
    get().setSearchTerm("", true);
    set({ page: 0 });

    if (!doNotUpdate) {
      get().updateEverything();
    }
  },

  setSearchTerm: (term, doNotUpdate = false) => {
    set({ searchTerm: (term || "").trim() });

    if (!doNotUpdate) {
      get().updateEverything();
    }
  },

  setPage: (pageIndex) => {
    set({ page: Math.max(0, Math.min(pageIndex, get().pageCount - 1)) });
    get().updateEverything();
  },
}));
