import { ProductFilterViewModel } from '../types/generated/sitecore.interface'
import { getErhvervsCenterAPIUrl } from '../helpers/api-url'
import {
  ABORT_CONTROLLER_SUPPORTED,
  PRODUCT_LIST_ENDPOINT,
} from '../../constants'

let abortController: AbortController | undefined

export const filterProducts = async (
  queryString = '',
): Promise<ProductFilterViewModel> => {
  if (ABORT_CONTROLLER_SUPPORTED) {
    if (abortController) {
      abortController.abort()
    }

    abortController = new AbortController()
  }

  return await fetch(
    `${getErhvervsCenterAPIUrl()}${PRODUCT_LIST_ENDPOINT}?${queryString}`,
    {
      signal: abortController?.signal,
    },
  ).then(response => response.json())
}
