import { SvgIcon } from '@material-ui/core';
import React from 'react';
import IconSource from '@tdcerhverv/parrotfish/dist/icons/Icons/Icon_5G_logo.svg';
import { TDCIconProps } from './TDCIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        fill: 'url(#Icon_5G_logo_svg__paint0_linear)',
    },
}));

export const Icon5G: React.FC<TDCIconProps> = React.memo(
    (props: TDCIconProps): JSX.Element => {
        const classes = useStyles();
        return <SvgIcon className={classes.root} component={IconSource} viewBox="0 0 48 48" {...props} />;
    },
);
