import { IS_BROWSER } from "../../constants";

const memoized: Record<string, number> = {};

/**
 * Get the perceived brightness of a color as percentage in decimal form.
 *
 * @param {string} color
 * @return number A number from 0 to 1, 1 being the brightest and 0 not bright at all.
 */
const colorBrightness = (color: string): number => {
    if (memoized[color] !== undefined) {
        return memoized[color];
    }

    const hexMatch = color.match(/^#((([0-9a-f]{3}){1,2})|(([0-9a-f]{4}){1,2}))$/i);

    if (hexMatch && hexMatch.length) {
        // Convert hex to RGB

        const hex = hexMatch[1];

        let red = 0;
        let green = 0;
        let blue = 0;

        if (hex.length === 3 || hex.length === 4) {
            // Hex is 3 or 4 characters - convert to RGB

            const rgb = [hex.charAt(0), hex.charAt(1), hex.charAt(2), hex.charAt(3) || 'F'];

            red = parseInt(rgb[0] + rgb[0], 16);
            green = parseInt(rgb[1] + rgb[1], 16);
            blue = parseInt(rgb[2] + rgb[2], 16);
        } else {
            // Hex is 6 or 8 characters - convert to RGB

            const rgb = [
                hex.charAt(0) + hex.charAt(1),
                hex.charAt(2) + hex.charAt(3),
                hex.charAt(4) + hex.charAt(5),
                hex.charAt(7) ? hex.charAt(6) + hex.charAt(7) : 'FF',
            ];

            red = parseInt(rgb[0], 16);
            green = parseInt(rgb[1], 16);
            blue = parseInt(rgb[2], 16);
        }

        // Calculate perceived brightness from RGB (http://alienryderflex.com/hsp.html)
        const perceivedBrightness =
            Math.sqrt(0.299 * (red * red) + 0.587 * (green * green) + 0.114 * (blue * blue)) / 255;

        memoized[color] = perceivedBrightness;

        return perceivedBrightness;
    }

    if (IS_BROWSER) {
        console.error(`Color must be in hex format: ${color}`);
    }

    return 0;
};

const lightColorThreshold = 0.65;

export const isBrightColor = (color: string): boolean => colorBrightness(color) > lightColorThreshold;
