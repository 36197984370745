import React from 'react';
import { Box, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { FilterVariant, ProductDetailVariantModel } from '../../types/generated/sitecore.interface';
import { isBrightColor } from '../../helpers/color-brightness.helper';
import tdcColors from '@tdcerhverv/parrotfish/dist/colors-design-token.js';
import CheckmarkIcon from '../../icons/generated/CheckmarkIcon';
import { Link } from 'gatsby'
import { routes } from '../../components/Router/routes'

type IVariantTypes = FilterVariant | ProductDetailVariantModel;

type IVariantSelectorProps = {
    variants: IVariantTypes[];
    setSelectedIndex?: (newIndex: number, variantCode: string) => void;
    selectedIndex?: number;
    limit?: boolean;
    large?: boolean;
};

type StyleProps = {
    large?: boolean;
    hasCallback?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: {
        alignSelf: 'center',
    },
    buttonList: {
        whiteSpace: 'nowrap',
    },
    selectorButton: {
        padding: 0,
        width: (props) => (props.large ? theme.spacing(2.5) : theme.spacing(1.5)),
        height: (props) => (props.large ? theme.spacing(2.5) : theme.spacing(1.5)),
        whiteSpace: 'nowrap',
        cursor: (props) => (props.hasCallback ? 'pointer' : 'inherit'),
        background: 'currentColor',
        appearance: 'none',
        display: 'inline-block',
        border: 'none',
        borderRadius: '50%',
        position: 'relative',
        marginRight: (props) => (props.large ? theme.spacing(1) : theme.spacing(7 / 8)),
        color: tdcColors.white,

        '&:last-child': {
            marginRight: 0,
        },

        '&.bright': {
            color: tdcColors.black,
            border: `1px solid currentColor`,
            boxSizing: 'border-box',
        },
    },
    limiter: {
        display: 'inline-block',
        userSelect: 'none',
        lineHeight: '1em',
        verticalAlign: 'bottom',
    },
    checkmark: {
        width: theme.spacing(1.375),
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));

const getColorFromVariant = (variant: IVariantTypes): string =>
    (variant as FilterVariant).ColorName || (variant as ProductDetailVariantModel).VariantName || '';

/** Workaround for Chromium browsers pushing the button slightly down when there's an inner element with position absolute */
const EmptySpan = () => <span style={{ position: 'absolute' }}></span>;

export const VariantSelector: React.FC<IVariantSelectorProps> = React.memo(
    ({ variants, selectedIndex = 0, setSelectedIndex, limit, large }: IVariantSelectorProps): JSX.Element | null => {
        const classes = useStyles({ large, hasCallback: !!setSelectedIndex });

        if (!variants) return null;

        const itemLimit = 3;
        // Only limit if there is at least two more items than we want to fit on the list. Otherwise,
        // the "+1" would take up the space the remaining color could render on.
        const shouldLimitItems = limit && variants.length > itemLimit + 1;
        const subsetToRender = shouldLimitItems ? variants.slice(0, itemLimit) : variants;

        return (
            <Box className={classes.root}>
                {variants.length > 1 && (
                    <div className={classes.buttonList}>
                        {subsetToRender.map(
                            (variantInfo, idx): JSX.Element => (
                                <Tooltip
                                    key={`${variantInfo.ColorCode}-${idx}`}
                                    title={getColorFromVariant(variantInfo)}
                                >
                                    <Link to={routes.productPage.build(variantInfo.VariantNo, '')}
                                        className={`${classes.selectorButton}${
                                            isBrightColor(variantInfo.ColorCode || '') ? ` bright` : ''
                                        }`}
                                        style={{ backgroundColor: variantInfo.ColorCode }}
                                        aria-label={getColorFromVariant(variantInfo)}
                                    >
                                        {selectedIndex === idx ? (
                                            <CheckmarkIcon className={classes.checkmark} />
                                        ) : (
                                            <EmptySpan />
                                        )}
                                    </Link>
                                </Tooltip>
                            ),
                        )}
                        {shouldLimitItems && (
                            <Typography variant="button" component="span" className={classes.limiter}>
                                +{variants.length - itemLimit}
                            </Typography>
                        )}
                    </div>
                )}
            </Box>
        );
    },
);
